import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import * as analytics from "legacy-js/analytics";
import { trackActivity } from "js/core/utilities/utilities";
import { presentations as presentationsApi } from "apis/callables";
import Api from "js/core/api";
import ErrorMessage from "legacy-js/app/ErrorMessage";
import { BEAUTIFUL_WORKSPACE_ID } from "legacy-common/constants";
import { LoadingPage } from "legacy-js/react/components/LoadingPage";
import getLocalStorage from "js/core/utilities/localStorage";
import getLogger from "js/core/logger";

const logger = getLogger();

const localStorage = getLocalStorage();

class CopyDeck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "pending"
        };
    }

    componentDidMount() {
        const { presentationId } = this.props.match.params;
        const params = new URLSearchParams(window.location.search);

        const workspaceId = localStorage.getItem(BEAUTIFUL_WORKSPACE_ID);
        presentationsApi.copyPresentation({ id: presentationId, workspaceId })
            .then(res => {
                const props = {
                    "presentationId": presentationId, // legacy
                    "presentation_id": presentationId,
                    "source": params.get("source") || "marketing",
                    "workspace_id": workspaceId
                };

                if (props.source === "marketing") {
                    props["presentation_source_id"] = presentationId;
                    props["presentation_source_name"] = res.sourceName;
                    props["presentation_source_thumbnail"] = res.sourceThumbnailUrl;
                    props["presentation_url"] = res.url;
                }

                trackActivity("Presentation", "GetDeck", null, null, props, { audit: true });

                // if user came from signup, signup is now complete
                // but first give the above analytics a chance to fire
                setTimeout(() => {
                    if (analytics.getState("is_signing_up")) {
                        analytics.trackState({
                            "is_signing_up": false,
                        });
                    }
                });

                window.baiSource = "copydeck";
                this.setState({
                    status: "succeeded",
                    relativeUrl: `/${res.presentationId}/1?with-appcue=1c65534f-fa6e-498c-b02a-fce139a94c85`,
                });
            })
            .catch(err => {
                logger.error(err, "[CopyDeck] Failed to copy presentation", { presentationId, workspaceId });
                this.setState({ status: "failed", error: err.message });
            });
    }

    render() {
        switch (this.state.status) {
            case "pending":
                return (
                    <LoadingPage />
                );
            case "succeeded":
                return (
                    <Redirect to={this.state.relativeUrl} />
                );
            case "failed":
                return (
                    <ErrorMessage message={"Failed to copy the presentation into your account. Please try again later."} />
                );
        }
    }
}

export default withRouter(CopyDeck);
